import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo/seo";
import { LogoBunny } from "../components/site/header/header";
import Navigation from "../components/site/navigation/navigation";
import { TOP_NAV_ITEM } from "../constants/top-navigations";
import "../graphql-fragments/static-queries/static-query-fragments";
import COVID19Home from "../components/covid-19/covid-19-home/covid-19-home";

const IndexPage: React.FC = () => (
  <Layout isTopicHomePage={true} headerUrl="/" logoBunny={LogoBunny.FaceMask}>
    <SEO title="Get quick answers for topics you concern" />

    <Navigation activeItem={TOP_NAV_ITEM.SiteHome} />

    <COVID19Home />
  </Layout>
);

export default IndexPage;
